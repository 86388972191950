import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import API from "../../API";
import CenteredContainer from "../Common/CenteredContainer";
import LoadingSpinner from "../Common/LoadingSpinner";

export default function Checkout({ cartItems }) {
  const [paymentUrl, setPaymentUrl] = useState(null);

  useEffect(() => {
    const fetchPaymentUrl = async () => {
      try {
        const res = await API.checkout(cartItems);
        if (res) {
          setPaymentUrl(res["url"]);
        }
      } catch (error) {
        console.error("Error fetching payment URL:", error);
      }
    };

    fetchPaymentUrl();
  }, [cartItems]);

  return paymentUrl ? (
    <Box
      component="iframe"
      sx={{ width: "100%", height: "100%", border: 0 }}
      src={`https://icom.yaad.net/p/?${paymentUrl}`}
    />
  ) : (
    <CenteredContainer sx={{ height: "100vh" }}>
      <LoadingSpinner />
    </CenteredContainer>
  );
}
