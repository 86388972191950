import parse, { domToReact } from "html-react-parser";
import { Container, Link, Typography } from "@mui/material";
import { useSharedContext } from "../../SharedContext";
import { useEffect, useState } from "react";
import CenteredContainer from "../Common/CenteredContainer";
import LoadingSpinner from "../Common/LoadingSpinner";
import { showPopUp } from "../Common/PopUp";

const StaticPage = ({ page }) => {
  const { t, i18n, isLoading, setIsLoading } = useSharedContext();
  const [content, setContent] = useState("");

  function replaceTags(htmlContent) {
    const options = {
      replace: (domNode) => {
        if (domNode.name === "h4") {
          return <Typography variant="h4">{domToReact(domNode.children, options)}</Typography>;
        }
        if (domNode.name === "h6") {
          return <Typography variant="h6">{domToReact(domNode.children, options)}</Typography>;
        }
        if (domNode.name === "p") {
          return <Typography>{domToReact(domNode.children, options)}</Typography>;
        }
        if (domNode.name === "a") {
          return (
            <Link href={domNode.attribs.href} target="_blank" rel="noopener noreferrer">
              {domToReact(domNode.children, options)}
            </Link>
          );
        }
      },
    };

    return parse(htmlContent, options);
  }

  const fetchContent = async () => {
    const locale = i18n.language === "he" ? "he" : "en";
    setIsLoading(true);
    try {
      const response = await fetch(`/pages/${page}/${locale}.html`);
      const text = await response.text();
      setContent(text);
      setIsLoading(false);
    } catch (error) {
      showPopUp("error", "An error has occured. Please reload the page");
    }
  };

  useEffect(() => {
    fetchContent();
  }, [page, i18n.language]);

  return (
    <>
      {!isLoading && content ? (
        <Container
          sx={{ pt: { xs: "80px", lg: "120px" }, pb: "68px", direction: i18n.language === "he" ? "rtl" : "ltr" }}
        >
          {replaceTags(content)}
        </Container>
      ) : (
        <CenteredContainer sx={{ height: "CALC(100vh - 50px)", mt: "50px" }} maxWidth={false}>
          <LoadingSpinner />
        </CenteredContainer>
      )}
    </>
  );
};

export default StaticPage;
