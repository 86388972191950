import { Container, Grid, TextField, Typography } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import PartnerButton from "../Common/PartnerButton";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import { useState } from "react";
import { useSharedContext } from "../../SharedContext";

export default function BuyerInfo({ is_include_delivery, setCheckoutPage }) {
  const { t, isRTL } = useSharedContext();

  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    city: "",
    street: "",
    house: "",
    entrance: "",
    apartment: "",
    deliveryNotes: "",
  });

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    city: "",
    street: "",
    house: "",
  });

  const validatePhoneNumber = (number) => {
    const regex = /^(0|972|\+972)?5[0-9]{8}$/;
    return regex.test(number);
  };

  const validateField = (name, value) => {
    let error = "";
    if (name === "firstName" && !value) error = t("requiredField");
    if (name === "lastName" && !value) error = t("requiredField");
    if (name === "phoneNumber") {
      if (!value) error = t("requiredField");
      else if (!validatePhoneNumber(value)) error = t("phoneNumberNotValid");
    }
    if (name === "email") {
      if (!value) error = t("requiredField");
      else if (!/^\S+@\S+\.\S+$/.test(value)) error = t("emailNotValid");
    }
    if (is_include_delivery) {
      if (name === "city" && !value) error = t("requiredField");
      if (name === "street" && !value) error = t("requiredField");
      if (name === "house" && !value) error = t("requiredField");
    }
    return error;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    if (errors[name]) {
      const fieldError = validateField(name, value);
      setErrors({ ...errors, [name]: fieldError });
    }
  };

  const validateFields = () => {
    let newErrors = {};
    Object.keys(formValues).forEach((name) => {
      const error = validateField(name, formValues[name]);
      if (error) newErrors[name] = error;
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateFields()) {
      setCheckoutPage(true);
    }
  };

  return (
    <Container
      sx={{
        bgcolor: "var(--package-card-color)",
        borderRadius: "30px",
        paddingY: "24px",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <Container sx={{ display: "flex", gap: "7px", alignItems: "center" }} disableGutters>
        <PersonIcon />
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          {t("customerInformation")}
        </Typography>
      </Container>
      <form style={{ display: "flex", flexDirection: "column", gap: "20px" }} onSubmit={handleSubmit}>
        <Grid
          sx={{
            "& .MuiTypography-root": { marginBottom: "5px" },
            "& .MuiOutlinedInput-root": { bgcolor: "white", borderRadius: "6px" },
            "& .MuiFormHelperText-root": { marginLeft: isRTL ? "auto" : "unset" },
          }}
          container
          spacing={3}
        >
          <Grid item xs={12} sm={6}>
            <Typography>{t("firstName")}*</Typography>
            <TextField
              name="firstName"
              placeholder={t("enterFirstName")}
              fullWidth
              value={formValues.firstName}
              onChange={handleChange}
              error={Boolean(errors.firstName)}
              helperText={errors.firstName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>{t("lastName")}*</Typography>
            <TextField
              name="lastName"
              placeholder={t("enterLastName")}
              fullWidth
              value={formValues.lastName}
              onChange={handleChange}
              error={Boolean(errors.lastName)}
              helperText={errors.lastName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>{t("contactPhoneNumber")}*</Typography>
            <TextField
              name="phoneNumber"
              placeholder={t("enterPhoneNumber")}
              fullWidth
              value={formValues.phoneNumber}
              onChange={handleChange}
              error={Boolean(errors.phoneNumber)}
              helperText={errors.phoneNumber}
              sx={{ direction: "ltr" }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography>{t("email")}*</Typography>
            <TextField
              name="email"
              placeholder={t("enterEmail")}
              fullWidth
              value={formValues.email}
              onChange={handleChange}
              error={Boolean(errors.email)}
              helperText={errors.email}
              sx={{ direction: "ltr" }}
            />
          </Grid>
        </Grid>
        {is_include_delivery && (
          <>
            <Container sx={{ display: "flex", gap: "7px", alignItems: "center" }} disableGutters>
              <DeliveryDiningIcon />
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                {t("deliveryDetails")}
              </Typography>
            </Container>
            <Grid
              sx={{
                "& .MuiTypography-root": { marginBottom: "5px" },
                "& .MuiOutlinedInput-root": { bgcolor: "white", borderRadius: "6px" },
                "& .MuiFormHelperText-root": { marginLeft: isRTL ? "auto" : "unset" },
              }}
              container
              spacing={3}
            >
              <Grid item xs={6}>
                <Typography>{t("city")}*</Typography>
                <TextField
                  name="city"
                  placeholder={t("enterCity")}
                  fullWidth
                  value={formValues.city}
                  onChange={handleChange}
                  error={Boolean(errors.city)}
                  helperText={errors.city}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography>{t("street")}*</Typography>
                <TextField
                  name="street"
                  placeholder={t("enterStreet")}
                  fullWidth
                  value={formValues.street}
                  onChange={handleChange}
                  error={Boolean(errors.street)}
                  helperText={errors.street}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography>{t("house")}*</Typography>
                <TextField
                  name="house"
                  placeholder={t("enterHouse")}
                  fullWidth
                  value={formValues.house}
                  onChange={handleChange}
                  error={Boolean(errors.house)}
                  helperText={errors.house}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography>{t("enterance")}</Typography>
                <TextField
                  name="entrance"
                  placeholder={t("enterEnterance")}
                  fullWidth
                  value={formValues.entrance}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography>{t("apartment")}</Typography>
                <TextField
                  name="apartment"
                  placeholder={t("enterApartment")}
                  fullWidth
                  value={formValues.apartment}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography>{t("deliveryNotes")}</Typography>
                <TextField
                  name="deliveryNotes"
                  placeholder={t("deliveryNotes")}
                  fullWidth
                  value={formValues.deliveryNotes}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </>
        )}
        <PartnerButton sx={{ width: "fit-content" }} type="submit">
          {t("continue")}
        </PartnerButton>
      </form>
    </Container>
  );
}
